









import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {computed, defineComponent, PropType} from '@vue/composition-api';
import LabTask from '@/tasks/components/dynamic-questions/labs/LabTask.vue';
import LabSim from '@/tasks/components/simulations/LabSim.vue';
import TaskState from '@/task-states/models/TaskState';
import {VariableValueType} from '@/task-states/types/RawTaskVariableValue';

export default defineComponent({
  name: 'AtomicEmissionSpectroscopySIM',
  components: {LabTask, LabSim},
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: TaskState as PropType<
        TaskState<{
          dValue: VariableValueType.NumberValue;
          unknownGas: VariableValueType.ChemicalElementValue;
        }>
      >,
      required: true,
    },
  },
  setup(props) {
    const dValue = computed(() => props.taskState.getValueBySymbol('dValue')?.content?.number);
    const unknownGasSymbol = computed(
      () => props.taskState.getValueBySymbol('unknownGas')?.content?.symbol
    );

    return {dValue, unknownGasSymbol};
  },
});
